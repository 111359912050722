// -------------------- BREAKPOINTS --------------------

// Extra-Small devices
@mixin xs {
  @media (max-width: calc(#{$screen-xs} + 1px)) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: calc(#{$screen-sm} - 1px)) {
    @content;
  }
}

// Medium and large devices
@mixin md {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

// Until tablet
@mixin m {
  @media (max-width: calc(#{$screen-md} - 1px)) {
    @content;
  }
}

// From tablet
@mixin m-lg {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

// Medium and small devices
@mixin sm-md {
  @media (max-width: calc(#{$screen-lg} - 1px)) {
    @content;
  }
}

// Everything but XLarge devices
@mixin sm-lg {
  @media (max-width: calc(#{$screen-xlg} - 1px)) {
    @content;
  }
}

@mixin lg-xl {
  @media (max-width: calc(#{$screen-xxlg} - 1px)) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

// XLarge devices
@mixin xlg {
  @media (min-width: #{$screen-xlg}) {
    @content;
  }
}

// XLarge devices
@mixin xxlg {
  @media (min-width: #{$custom-screen-lg-1-2}) {
    @content;
  }
}

// From max width
@mixin maxw {
  @media (min-width: #{$body-max-width} ) {
    @content;
  }
}

// To use as follows: .element { @include sm { styles for this screen resolution } }

// -------------------- SHAPES AND DECORATION --------------------

@mixin round-corners {
  border-radius: $gutter;
}

@mixin shadow {
  box-shadow: 0 0 8px #{$gray-6}#{5c};
}

// -------------------- POSITION --------------------

@mixin absolute-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

@mixin absolute-0 {
  position: absolute;
  top: 0;
  left: 0;
}

@mixin fixed-0 {
  position: fixed;
  top: 0;
  left: 0;
}

@mixin fullscreen {
  height: 100vh;
  width: 100vw;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-row-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// -------------------- SPACING --------------------

@mixin nom-nop {
  margin: 0;
  padding: 0;
}

// -------------------- PARTICULAR ITEMS --------------------

@mixin navigation-icons {
  height: $gutter-4;
  width: $gutter-4;
  padding: 0;
  border: none;
  color: $color-6;
  background: none;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    height: $gutter-4;
    width: $gutter-4;
  }

  &:hover {
    color: $color-5;
  }
}

@mixin cifres {
  font-family: $font-primary;
  font-weight: 900;

  @include sm {
    font-size: $fontsize-xlarge;
  }

  @include md {
    font-size: $fontsize-xxlarge;
  }
}

@mixin container-fullscreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-y: scroll;
}
