@import 'styles/tools/mixins.scss', 'styles/settings/variables.scss';

.publish-campaign-banner {
  &__text {
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;

    @include sm {
      display: inline-block;
    }

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__alert {
    min-height: 48px;
    background: #00A742 !important;
    @include sm {
      padding: 8px !important;
      height: 65px;
    }
    &-desktop {
      height: 0px;
    }
    &-profile{
      background: $red-1 !important;
      border: 1px solid $red-3 !important;
      border-radius: 8px !important;
    }
  }
}
