@keyframes wizard-in {
  0% {
    right: -100vw;
  }
  100% {
    right: $gutter-2;
  }
}

@keyframes wizard-out {
  0% {
    right: $gutter-2;
  }
  100% {
    right: 100vw;
  }
}

@keyframes wizard-reverse-in {
  0% {
    left: -100vw;
  }
  100% {
    left: $gutter-2;
  }
}

@keyframes wizard-reverse-out {
  0% {
    left: $gutter-2;
  }
  100% {
    left: 100vw;
  }
}

@keyframes wizard-in__md {
  0% {
    right: -100vw;
  }
  100% {
    right: $gutter-2;
  }
}

@keyframes wizard-out__md {
  0% {
    right: $gutter-2;
  }
  100% {
    right: 100vw;
  }
}

@keyframes wizard-reverse-in__md {
  0% {
    left: -100vw;
  }
  100% {
    left: $gutter-2;
  }
}

@keyframes wizard-reverse-out__md {
  0% {
    left: $gutter-2;
  }
  100% {
    left: 100vw;
  }
}

.wizard {
  &--right-to-center {
    right: $gutter-2;
    animation: wizard-in 0.4s ease-in-out;
    margin-bottom: $gutter-4;

    @include md {
      right: 0;
      animation: wizard-in__md 0.4s ease-in-out;
    }
  }

  &--center-to-left {
    right: 100vw;
    animation: wizard-out 0.4s ease-in-out;

    @include md {
      right: 100%;
      animation: wizard-out__md 0.4s ease-in-out;
    }
  }

  &--left-to-center {
    left: $gutter-2;
    animation: wizard-reverse-in 0.4s ease-in-out;

    @include md {
      left: 0;
      animation: wizard-reverse-in__md 0.4s ease-in-out;
    }
  }

  &--center-to-right {
    left: 100vw;
    animation: wizard-reverse-out 0.4s ease-in-out;

    @include md {
      left: 100%;
      animation: wizard-reverse-out__md 0.4s ease-in-out;
    }
  }
}
