@import 'styles/tools/mixins.scss', 'styles/settings/variables.scss';

.opportunity-page {
  display: flex;
  position: relative;
  margin-top: -64px;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  justify-content: center;
  margin-left: -100px;

  @include sm-md {
    margin-top: 0px;
    flex-direction: column;
    height: 100vh;
    min-height: 100vh;
    min-height: -moz-available;
    min-height: -webkit-fill-available;
    min-height: stretch;
    max-height: -webkit-fill-available;
  }

  @include sm {
    margin-left: 0;
  }

  .ant-upload-list-picture-card {
    margin-top: 16px;
  }

  .btn-is-active {
    background: #e5f6ec !important;
    color: #19202b;
    border-width: 1px !important;
  }

  .location-btn {
    &:hover {
      background: #e5f6ec !important;
      color: #19202b;
      border-color: #00a742 !important;
    }
  }

  &__network {
    margin-bottom: 24px;
    min-width: 32% !important;
    max-width: 32% !important;

    &-btn.ant-btn {
      width: 144px;
      display: flex;
      align-items: center;

      span {
        font-weight: 500 !important;
        margin-left: 0 !important;
      }

      &:focus {
        border: 1px solid $color-3 !important;
        color: #00a742;
      }

      &.-is-active {
        border-color: $color-3 !important;
        background: #e5f6ec !important;
        color: #00a742;

        span {
          font-weight: 700 !important;
        }

        svg {
          fill: #00a742;

          path {
            fill: #00a742;
          }
        }
      }

      &.-is-invalid {
        border-color: #ff4d4f !important;
        background-color: #faf2f2 !important;
        color: #ff4d4f !important;

        svg {
          fill: #ff4d4f;

          path {
            fill: #ff4d4f;
          }
        }
      }
    }
  }

  &__deal {
    &-checkbox {
      width: 40px;
    }
    &-description {
      margin-left: 8px;
      padding-top: 2px;
    }

    &-inputGroup {
      width: 100%;
      display: flex;
      .ant-input-number {
        max-width: 150px !important;
      }
    }

    &-input {
      margin-left: 48px;
      display: flex;
      flex-direction: column;

      @include sm {
        margin-left: 32px;
        margin-top: 10px !important;
      }
    }
  }

  &__delivery {
    @include sm {
      flex-direction: column;
    }

    label {
      width: 300px !important;
      display: flex;
      align-items: center;

      @include sm {
        margin-bottom: 4px;
      }

      &:first-child {
        border-start-start-radius: 8px !important;
        border-end-start-radius: 8px !important;

        @include sm {
          border-radius: 8px !important;
        }
      }

      &:last-child {
        border-start-end-radius: 8px;
        border-end-end-radius: 8px;

        @include sm {
          border-radius: 8px !important;
          border-left: 1px solid #d9d9d9;
        }
      }

      &.ant-radio-button-wrapper-checked,
      &.ant-radio-button-wrapper:hover {
        border-color: $color-3 !important;
        color: $color-4 !important;
        background: #e5f6ec !important;
      }

      &.ant-radio-button-wrapper {
        @include sm {
          &::before {
            display: none;
          }
        }
      }

      &.ant-radio-button-wrapper-checked {
        &::before {
          background: $color-3 !important;
        }
      }

      &.ant-radio-button-wrapper:hover {
        &::before {
          background: $color-3 !important;
        }
      }

      span {
        display: flex;
        align-items: center;
      }
    }
  }

  &__info {
    top: 30px;
    right: 120px;

    @include sm-md {
      left: 150px;
      right: auto;
    }

    &.-is-english {
      @include sm-md {
        right: auto;
        left: 160px;
      }
    }

    &-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
    }
  }

  &__encourage {
    &-text {
      padding: 2px 4px;
      background: $green-1;
      border-radius: 4px;
      font-weight: 700;
      font-size: 11px;
      line-height: 24px;
      font-family: 'Open Sans';
      color: $green-2;
    }

    &-info {
      display: flex;
      flex-direction: column;
      padding: 16px;
      background: #fff;
      border: 1px solid #00a742;
      border-radius: 8px;
      margin-top: 12px;
      margin-bottom: 12px;

      @include sm {
        margin-left: 12px;
        margin-right: 12px;
      }

      &__icon {
        margin-right: 8px;
        svg {
          color: #19202b;
          position: relative;
        }
      }
    }
  }

  &__help {
    top: 30px;
    right: 260px;
    display: flex;
    align-items: center;

    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 7px;
    }

    @include sm-md {
      left: 8px;
      right: auto;
    }

    &.-is-english {
      right: 230px;

      @include sm-md {
        right: auto;
      }
    }
  }

  &__info,
  &__help {
    padding: 4px 12px;
    border: 1px solid $gray-4;
    border-radius: 40px;
    cursor: pointer;
    background: $gray-1;
    z-index: 9;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 32px;
    margin-right: 16px;

    @include sm-md {
      margin-right: 8px;
      top: 13px;
    }

    &:hover {
      background-color: $gray-3;
    }
  }

  &__logo {
    position: absolute !important;
    top: 30px;
    left: 60px;
    z-index: 99;

    @include sm-md {
      display: none;
    }
  }

  &__toolbar {
    width: 100%;
    height: 100px;
    top: 0;
    background: $gray-1;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 64px;
    height: 80px;

    @include sm-md {
      padding-right: 8px;
      background: #fff !important;
    }

    @include sm-md {
      &:not(.-is-first) {
        position: absolute;
      }
      background: transparent;
      z-index: 10;
      height: 56px;
    }

    &-close {
      border-radius: 25px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include sm {
        background-color: #ffffff59; //special color
        border-radius: 20px;
      }

      &:hover {
        background-color: $gray-3;
      }
      &-white {
        @include sm-md {
          span {
            color: white !important;
          }
        }
      }
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 40px;

    @include sm-md {
      line-height: 30px;
    }
  }

  &__banner {
    width: 50%;
    position: relative;
    z-index: 9;

    @include sm-md {
      min-height: 190px;
      width: 100%;
    }

    &.-first-step {
      @include sm-md {
        display: none !important;
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 100vh;
      background: rgb(255, 255, 255);
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 39%, rgba(97, 109, 120, 1) 100%);
      position: absolute;
      left: 0;
      opacity: 1;
      mix-blend-mode: multiply;

      @include sm-md {
        background: linear-gradient(180deg, $color-3 0%, #205099 100%);
        height: 100%;
      }
    }

    &-text {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      font-weight: 700;
      font-size: 48px;
      line-height: 55px;
      color: $gray-1;
      max-width: 585px;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      align-items: center;
      top: 0;
      bottom: 0;
      z-index: 9;

      @include sm-md {
        font-size: 26px;
        line-height: 32px;
        position: absolute;
        margin-left: 10px;
        margin-right: 10px;
        width: 95%;
        justify-content: flex-start;
      }

      &.-extra {
        @include sm-md {
          display: inline-flex;
          justify-content: flex-start;
          text-align: left;
        }
      }
    }

    img {
      width: 100%;
      object-fit: cover;
      height: 100vh;

      @include sm-md {
        display: none;
      }
    }
  }

  &__form {
    display: flex;
    height: calc(100vh - 206px);
    overflow: scroll;
    padding: 0 72px;
    justify-content: center;
    flex-direction: column;

    @include sm {
      min-height: calc(100vh - 206px);
    }

    @include sm {
      overflow-x: hidden;
    }
  }

  &__next {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: $gray-1;
    z-index: 9;
    position: relative;

    button {
      min-width: 166px;

      &.-back {
        width: auto;
      }
    }

    &-buttons {
      padding: 20px 90px;
      @include sm-md {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @include sm-md {
      width: 100%;
    }

    &-progress {
      position: absolute;
      top: -15px;
      width: 100%;

      @include sm {
        background: #fff;
        padding-top: 10px;
      }

      .ant-progress-steps-item {
        width: calc((100% / 6) - 8px) !important;
      }
    }
  }

  &__content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include sm-md {
      width: 100%;
    }

    &.-first-step {
      @include sm-md {
        min-height: 100vh;
        min-height: -moz-available;
        min-height: -webkit-fill-available;
        min-height: stretch;
      }
    }

    h1 {
      font-weight: 700;
      font-size: 48px;
      line-height: 58px;
      max-width: 80%;
      text-align: center;
      margin-bottom: 24px;

      @include sm-md {
        font-size: 24px;
        line-height: 34px;
      }
    }

    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;
      max-width: 80%;
      text-align: center;

      @include sm-md {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    justify-content: space-between;

    .-categ-step {
      max-width: 50% !important;

      @include sm {
        max-width: 100% !important;
      }
    }

    .location-search {
      &:hover {
        border-color: #00a742 !important;
      }
    }

    @include sm-md {
      padding-top: 24px !important;
      justify-content: flex-start;
    }

    &.-is-pictures {
      .ant-upload-list-item-actions {
        button {
          margin-left: auto;
          margin-right: auto;
        }
      }

      .opportunity-page__container-section {
        height: calc(100vh - 160px) !important;
      }
    }

    &-location {
      overflow-y: hidden;
      max-height: 75vh;
      width: 50%;

      &__mobile {
        overflow-y: scroll;

        .ant-radio-button-wrapper {
          margin-right: 0 !important;
          margin-left: 0 !important;
        }
      }
    }

    &-type {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100;
      margin-bottom: 32px;

      @include sm-lg {
        flex-direction: column;
        align-items: start;
        .opportunity-page__deal-inputGroup {
          justify-content: flex-start;
          :nth-child(2) {
            margin-left: 0;
          }
        }
        .opportunity-page__deal-input {
          margin-top: 24px;
          align-items: flex-start;
        }
      }
    }

    &-section {
      max-width: 780px;
      width: 100%;
      margin: 0 auto;

      &.-is-deal {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      @include sm {
        padding: 8px 0;
        width: 100%;
      }
    }

    &-tag-container {
      height: 60px;
      width: 100%;
      border-radius: 8px !important;
      border: 1px solid #d9d9d9; //this color is from antd, to simulate a input select
      display: flex;
      align-items: center;
      padding: 10px;
      background-color: $gray-1;
      @include sm {
        overflow-x: scroll;
        max-width: 300px;
        height: auto;
        flex-wrap: wrap;
      }
      &:hover {
        border-color: $gray-6 !important;
        outline: none;
        box-shadow: none;
      }
      &__placeholder {
        color: rgba(0, 0, 0, 0.25); //this color is from antd, to simulate a input select paceholder
      }
    }

    &.en {
      .ant-upload-list-picture-card-container {
        &:first-child {
          &::after {
            content: 'Cover image';
          }
        }
      }
    }
    &.es {
      .ant-upload-list-picture-card-container {
        &:first-child {
          &::after {
            content: 'Foto de portada';
          }
        }
      }
    }

    @include sm-md {
      width: 100%;
      padding: 0 8px;
      overflow-x: hidden;

      @media screen and (max-width: 387px) {
        padding: 0 8px;
      }
    }

    &.-has-deliver,
    &.-has-visit {
      background-repeat: no-repeat;
      background-size: cover;
      justify-content: flex-start;

      @include sm-md {
        padding-top: 24px;
      }
    }

    h3 {
      width: 100%;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
      font-family: 'Open Sans';

      span:not(.is-bold) {
        font-weight: 400 !important;
      }
    }

    h4 {
      width: 100%;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 2px;
      font-family: 'Open Sans';

      span:not(.is-bold) {
        font-weight: 400 !important;
      }
    }

    &.-deal {
      .ant-checkbox-checked .ant-checkbox-inner {
        margin-bottom: 16px;
      }
    }

    &.-delivery {
      .ant-radio-group {
        @include sm {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &.-link,
    &.-description,
    &.-extra {
      input {
        height: 40px !important;
        border-radius: 8px !important;

        @include sm {
          font-size: 16px;
        }
      }
    }

    &.-extra {
      .ant-input-affix-wrapper {
        align-items: center;
        overflow: hidden;
      }
    }

    &.-description {
      .ant-input-textarea {
        width: 100%;
      }
    }

    .checklist-label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 2px;
      height: 24px;
      font-family: 'Open Sans';

      &__info {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray-5;
        font-family: 'Open Sans';
        text-align: end;

        @include sm {
          font-size: 16px;
        }
      }

      &__icon {
        margin-right: 10px;
        width: 19px;
        margin-top: 4px;
      }

      &__details {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $gray-5;
      }
    }

    .ant-select {
      max-width: 150px;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }

  &__category-btns {
    > div {
      @include sm-md {
        width: 100%;
        padding: 0 10px;
        display: flex !important;
        justify-content: space-around;
      }
    }

    label {
      height: 100%;
      width: 100%;
      margin: 3px;
      border-radius: 8px !important;
      border: 1px solid $gray-4;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 90px;

      &:hover {
        color: inherit;
        background: #e5f6ec !important;
        border-color: $color-3;
      }
    }

    .ant-row {
      justify-content: center;
    }

    .ant-radio-button-wrapper {
      display: flex;
      flex-direction: column;
      font-weight: 700;
      font-size: 11px;
      line-height: 20px;
      text-align: center;

      img {
        margin-bottom: 8px;
        height: 22px;
      }
    }

    .ant-radio-button-wrapper > .ant-radio-button {
      z-index: 1;
    }

    .ant-radio-button-wrapper {
      &::before {
        display: none !important;
      }
    }

    .ant-radio-button-wrapper-checked {
      color: $color-3 !important;
      border-color: $color-3 !important;
      background: #e5f6ec !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &__image-container {
    height: 200px;

    &-divider {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
      position: relative;
      color: #6f7585;

      &::after {
        content: '';
        display: block;
        background-color: #6f7585;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
      }

      span {
        background-color: white;
        z-index: 99;
        padding: 0 16px;
        text-transform: uppercase;
      }
    }

    &-icon {
      position: absolute;
      right: 19px;
      top: 10px;
      font-size: 20px;
    }
  }

  &__suggested-image {
    height: 150px;
    overflow: hidden;
    position: relative;

    @include sm-md {
      height: 250px;
    }
  }
}

// @media screen and (max-height: 845px) {
@media screen and (max-height: 1100px) {
  div.opportunity-page__form {
    justify-content: flex-start !important;
  }
}

@media screen and (max-height: 570px) {
  .opportunity-page__container.-description div.opportunity-page__form,
  .opportunity-page__container.-link div.opportunity-page__form {
    justify-content: flex-start !important;
  }
}

@media screen and (max-height: 770px) {
  .opportunity-page__container.-deal div.opportunity-page__form {
    justify-content: flex-start !important;
    padding-bottom: 30px;
  }
}

div.opportunity-page__form,
.opportunity-page__container.-deal div.opportunity-page__form,
.opportunity-page__container.-description div.opportunity-page__form,
.opportunity-page__container.-link div.opportunity-page__form {
  @include sm-md {
    justify-content: flex-start !important;
    padding: 0;
  }
}

.ant-upload.ant-upload-select {
  width: 100% !important;
  margin: 0 !important;

  @include sm {
    margin-right: 0;
  }
}

.discard-modal {
  &__subtitle {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 16px;
    line-height: 24px;
    margin-top: 19px;
  }

  &__subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  &__content {
    justify-content: space-between;
    background: $gray-2;
    border: 1px solid $gray-3;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 32px;
    padding-left: $gutter-2;
    padding-bottom: 16px;
  }

  &__banner {
    min-width: 131px;
    object-fit: cover;
    max-width: 131px;

    @include sm {
      min-width: 120px;
      max-width: 120px;
    }
  }
}
