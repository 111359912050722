@import 'styles/tools/mixins.scss';
@import 'styles/settings/variables.scss';

.error-page {
  height: calc(100vh - 64px);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 150px;

    @include sm {
        font-size: 90px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 24px;
  }

  button {
    width: auto;
    height: 40px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
}
