@import '../../../../styles/settings/variables.scss';

.navbar__language {
  width: 46px;
  min-width: 46px;
  cursor: pointer;

  &.-not-logged-in {
    width: 54px;
    min-width: 54px;
  }

  &-menu {
    z-index: 1101 !important;
  }

  &-menu {
    overflow: visible !important;
  }

  &.-is-mobile {
    width: 100%;
    padding: 13px 16px;
    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }

  &.-is-footer {
    width: 180px;
    background-color: $gray-1;
    height: 40px;
    border: 1px solid $gray-3;
    border-radius: 4px;
    margin-bottom: 40px !important;
    padding: 0 13px;

    p {
      display: flex;
      align-items: center;
      justify-content: space-between !important;
      width: 100%;
    }

    &:hover {
      border-color: $color-3;
    }
  }

  &-is-active {
    color: $gray-6 !important;
  }

  &.-is-navbar {
    border-radius: 30px !important;
    height: calc(8px * 4) !important;
    width: 55px;

    &:hover {
      background: #e8ebec;
    }
  }
}
