.offer {
  &--container {
    grid-template-rows: $gutter-12 $gutter-12 $gutter-12;
    column-gap: $gutter;
    grid-template-areas:
      'publications estimation1'
      'stories estimation2'
      'reward reward';
    width: calc(100% - #{$gutter});
  }

  .input-textarea {
    width: 100%;
  }

  &__additional-money {
    padding-left: 33px;
    font-size: 20px !important;
    line-height: 32px;
    font-weight: bold;

    @include sm-md {
      padding-left: $gutter-2;
    }
  }

  &__select {
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
  }

  &--publications {
    grid-row-start: publications;
    grid-column-start: publications;
    grid-row-end: estimation1;
    grid-column-end: estimation1;
  }

  &--stories {
    grid-row-start: stories;
    grid-column-start: stories;
    grid-row-end: estimation1;
    grid-column-end: estimation1;
  }

  &--reward {
    grid-area: reward;

    .form-error-message {
      z-index: -1;
    }

    span {
      font-size: $fontsize-large;
      position: absolute;
      top: calc(#{$gutter} * 5);
      left: auto;
      right: $gutter-10;
      color: $gray-6;

      @include sm {
        right: $gutter-7;
      }

      @include md {
        top: calc(#{$gutter} * 5);
      }
    }

    &-combo {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & > * {
        width: calc(50% - #{$gutter});
      }

      &.offer--reward-combo label {
        text-align: left;
      }

      .input-text,
      .input-select {
        margin-bottom: 0;
      }
    }
  }
}
