// CSS for printing PDFs

.print-container {
  width: 210mm;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin: auto;
}

html.printable-page {
  height: unset;
  min-height: 100vh;
  body {
    height: unset;
    min-height: 100vh;
  }
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  #root {
    padding: 0;
  }
  @media print {
    .-no-print {
      display: none !important;
    }
  }
}

@page {
  size: a4 portrait;
  margin-left: 0;
  margin-right: 0;
  margin-top: 30px;
  margin-bottom: 0;
}
