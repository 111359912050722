@import '../../styles/tools/mixins.scss', '../../styles/settings/variables.scss';

.request-brand-details-modal {
  &__btn {
    align-items: center;
    display: flex;
  }

  .signup {
    &__fees {
      justify-content: space-between;

      &.-sm {
        .signup__select {
          width: calc(50% - 8px);
        }
      }

      @include sm {
        display: flex;
        flex-direction: column;
      }

      > div {
        width: calc(100% / 4 - 8px);

        @include sm {
          width: 100%;
        }
      }
    }

    &__select {
      margin-bottom: 16px;
    }
  }

  &__label {
    color: rgba(35, 37, 66, 0.88);
    font-size: 14px;
    margin-bottom: 0;
  }
}
