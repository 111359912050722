// Positioning in the opportunity card

.card-opportunity--container {
  .toggle {
    display: none;
    position: absolute;
    right: $gutter;
    top: $gutter;
  }

  &.toggle__available {
    position: relative;

    .toggle {
      display: block;
    }

    .card-opportunity--title {
      @include sm {
        padding-right: $gutter-8;
      }
    }
  }
}

// General styling for the component

.toggle {
  width: $gutter-6;
  height: $gutter-4;
  padding: 0;
  cursor: pointer;
  z-index: 5;

  &--slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 28px;
    border-radius: $gutter-2;
    border: 2px solid $gray-4;
    background: $gray-1;
    transition: all 0.2s ease-out;

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      height: $gutter-3;
      width: $gutter-3;
      border-radius: 50%;
      background-color: $gray-4;
      transition: all 0.2s ease-out;
    }
  }
}

.form-toggle-text {
  margin-left: $gutter-8;
  &--title {
    margin-bottom: 0;
  }
  &--description {
    margin-top: 0;
    font-size: $fontsize-xsmall;
  }
}
