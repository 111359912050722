@import 'styles/tools/mixins.scss', 'styles/settings/variables.scss';

.home-header {
  height: $gutter-6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding-left: $gutter-2;
  padding-right: $gutter-2;
  box-sizing: border-box;
  border-bottom: 1px solid $gray-3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 220;
  background: $gray-1;

  button {
    background-color: transparent !important;
  }

  &--homepage {
    background: transparent;
    border-bottom: none;

    .icon-menu:before {
      color: $gray-1;
    }

    &-scrolled {
      background: $gray-1;
      border-bottom: 1px solid $gray-3;
      .icon-menu:before {
        color: $gray-6 !important;
      }
    }
  }

  &--find-creators {
    @include sm {
      display: none;
    }
  }
  &--dropdown-open {
    background-color: $gray-1;
    .icon-menu:before {
      color: $gray-6 !important;
    }
  }
  @include m-lg {
    pointer-events: none;
    display: none;
  }

  .picture,
  .picture__picture,
  img {
    height: 20px;
  }

  &--logo {
    width: 100px;
    min-height: 24px;
  }
  .icon-menu {
    height: $gutter-3;
    width: $gutter-3;
  }
  &__dropdown {
    position: absolute;
    background: $gray-1;
    width: 100%;
    display: flex;
    z-index: 50;
    box-sizing: border-box;
    flex-direction: column;
    max-height: 0;
    opacity: 0;
    transition: all 0.5s ease;
    pointer-events: none;
    top: $gutter-6;
    &--open {
      pointer-events: initial;
      opacity: 1;
      position: fixed;
      max-height: 350px;
      z-index: 200;
    }
    &-type {
      display: flex !important;
      justify-content: space-between !important;
      padding: 13px $gutter-2 !important;
      height: 56px !important;
    }
    &-buttons {
      padding: $gutter-2;
      border-top: 1px solid $gray-3;
      display: flex;
    }
  }
}
