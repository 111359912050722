@import 'styles/tools/mixins.scss', 'styles/settings/variables.scss';

.survey-container {
    position: fixed;
    bottom: 20px;
    z-index: 99999;
    background: #f2f5f9;
    box-shadow: 0 2px 4px -1px rgba(57, 76, 96, 0.15);
    max-width: 550px;
    width: 100%;
    right: 100px;
    padding: 8px 16px 16px;
    border: 1px solid #e8ebec;
    border-radius: 8px;

    @include sm {
        right: 0;
        left: 0;
        bottom: 60px;
        padding: 8px;
    }
}