
.environment_banner {
    position: fixed;
    top: 0;
    right: auto;
    width: auto;
    height: 30px;
    background: lightskyblue;
    z-index: 999;
    border-radius: 5px;
    margin: 7px;
    padding-top: 3px;
    padding-right: 10px;
    padding-left: 10px;
    &__close{
        cursor: pointer;
    }
}