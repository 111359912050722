/* Basic styling for icons */
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'Moonicon' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  height: $gutter-3;
  width: $gutter-3;
  font-size: $gutter-3;
  line-height: $gutter-3;
  font-weight: 400;
}
.icon-fashion:before {
  content: '\e92f';
}
.icon-food:before {
  content: '\e930';
}
.icon-beauty:before {
  content: '\e931';
}
.icon-deco:before {
  content: '\e932';
}
.icon-hotel:before {
  content: '\e933';
}
.icon-sport:before {
  content: '\e934';
}
.icon-kids:before {
  content: '\e935';
}
.icon-other:before {
  content: '\e936';
}
.icon-mini-search:before {
  content: '\e92e';
}
.icon-filters:before {
  content: '\e92d';
}
.icon-mini-heart:before {
  content: '\e92b';
}
.icon-mini-comment:before {
  content: '\e92c';
}
.icon-folder-add:before {
  content: '\e92a';
}
.icon-folder:before {
  content: '\e929';
}
.icon-micro-cross:before {
  content: '\e927';
}
.icon-mini-add:before {
  content: '\e928';
}
.icon-engagement:before {
  content: '\e924';
}
.icon-followers:before {
  content: '\e925';
}
.icon-mini-pinpoint:before {
  content: '\e926';
}
.icon-category:before {
  content: '\e923';
}
.icon-pinpoint:before {
  content: '\e920';
}
.icon-tiktok:before {
  content: '\e921';
}
.icon-youtube:before {
  content: '\e922';
}
.icon-logout:before {
  content: '\e91f';
}
.icon-mini-arrow:before {
  content: '\e91e';
}
.icon-copyright:before {
  content: '\e900';
}
.icon-mini-chevron:before {
  content: '\e901';
}
.icon-reward:before {
  content: '\e902';
}
.icon-target:before {
  content: '\e903';
}
.icon-trash:before {
  content: '\e904';
}
.icon-mini-cross:before {
  content: '\e905';
}
.icon-mini-clock:before {
  content: '\e906';
}
.icon-mini-tick:before {
  content: '\e907';
}
.icon-alert:before {
  content: '\e908';
}
.icon-document:before {
  content: '\e909';
}
.icon-updown:before {
  content: '\e90a';
}
.icon-edit:before {
  content: '\e90b';
}
.icon-info:before {
  content: '\e90c';
}
.icon-faq:before {
  content: '\e90d';
}
.icon-camera:before {
  content: '\e90e';
}
.icon-search:before {
  content: '\e90f';
}
.icon-lock:before {
  content: '\e910';
}
.icon-attach:before {
  content: '\e911';
}
.icon-instagram:before {
  content: '\e912';
}
.icon-profile:before {
  content: '\e913';
}
.icon-photo:before {
  content: '\e914';
}
.icon-home:before {
  content: '\e915';
}
.icon-calendar:before {
  content: '\e916';
}
.icon-gifts:before {
  content: '\e917';
}
.icon-messages:before {
  content: '\e918';
}
.icon-tick:before {
  content: '\e919';
}
.icon-cross:before {
  content: '\e91a';
}
.icon-arrow:before {
  content: '\e91b';
}
.icon-chevron:before {
  content: '\e91c';
}
.icon-link:before {
  content: '\e91d';
}
.icon-rating:before {
  content: '\e937';
}
.icon-print:before {
  content: '\e938';
}
.icon-mini-post:before {
  content: '\e939';
}
.icon-mini-payment:before {
  content: '\e93a';
}
.icon-mini-delivery:before {
  content: '\e93b';
}
.icon-add:before {
  content: '\e93c';
}
.icon-share-whatsapp:before {
  content: '\e93d';
}
.icon-share-twitter:before {
  content: '\e93e';
}
.icon-share-messenger:before {
  content: '\e93f';
}
.icon-share-facebook:before {
  content: '\e940';
}
.icon-copy:before {
  content: '\e941';
}
.icon-party:before {
  content: '\e942';
}
.icon-delivery:before {
  content: '\e943';
}
.icon-settings:before {
  content: '\e944';
}
.icon-public-profile:before {
  content: '\e945';
}
.icon-delivery:before {
  content: '\e943';
}
.icon-settings:before {
  content: '\e944';
}
.icon-rocket:before {
  content: '\e946';
}
.icon-mini-edit:before {
  content: '\e947';
}
.icon-facebook:before {
  content: '\ea91';
}
.icon-twitter:before {
  content: '\ea96';
}
.icon-stats:before {
  content: '\e948';
}
.icon-mini-trash:before {
  content: '\e949';
}
.icon-support:before {
  content: '\e94a';
}
.icon-mini-info:before {
  content: '\e94b';
}
.icon-download:before {
  content: '\e94c';
}
.icon-menu:before {
  content: '\e94d';
  color: $gray-6;
}
.icon-linkedin:before {
  content: '\e94e';
}
.icon-minus:before {
  content: '\e94f';
}
.icon-plus:before {
  content: '\e950';
}
.icon-collabs:before {
  content: '\e951';
}
.icon-edit-new:before {
  content: '\e952';
}
.icon-upload:before {
  content: '\e953';
}
.icon-social:before {
  content: '\e954';
}
.icon-pencil:before {
  content: '\e955';
}
.icon-heart:before {
  content: '\e956';
}
.icon-gift-mini:before {
  content: '\e957';
}
.icon-hand:before {
  content: '\e958';
}
.icon-team-line:before {
  content: '\e959';
}
.icon-moon:before {
  content: '\e95a';
}
.icon-green-heart:before {
  content: '\e95b';
  color: $green-2;
}
.icon-blue-heart:before {
  content: '\e95b';
  color: #00A742;
}
.icon-red-cross:before {
  content: '\e95c';
  color: $red-3;
}
.icon-clock:before {
  content: '\e95d';
  color: #00A742;
}
.icon-check:before {
  content: '\e95f';
}
.icon-double-check:before {
  content: '\e95e';
}
.icon-fire:before {
  content: '\e960';
}
