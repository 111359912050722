@keyframes collaboration-in {
  0% {
    left: 100vw;
  }
  100% {
    left: 0vw;
  }
}

.collaboration {
  &--container {
    display: flex;
    flex-direction: column;
    background: $gray-1;
    width: 100%;

    @include sm-md {
      &.hidden {
        .collaboration-header--container,
        .str-chat__input-flat {
          left: 100vw;
          opacity: 0;
          transition: left 0.4s ease-in, opacity 0.1s 0.4s linear;
        }
      }

      &.show {
        height: -webkit-fill-available;

        .collaboration-header--container,
        .str-chat__input-flat {
          left: 0vw;
          opacity: 1;
          transition: down 0.4s ease-out, opacity 0s linear;
        }
      }
    }

    @include sm {
      height: 100vh !important;
    }
  }

  &-header--container {
    z-index: 9;
  }

  &-page {
    @include sm {
      height: 100vh;
    }
  }
}

@include lg {
  .collaboration {
    &-page {
      display: flex;
      flex-direction: row;
      flex: 1;
      overflow: hidden;
      height: calc(100vh - 64px);
    }

    &--container {
      min-height: min-content;
      width: 50%;
      position: relative;
      box-sizing: border-box;
      flex-grow: 1;

      .conversation-container {
        &.-has-banner {
          height: 100% !important;

          .str-chat.str-chat-channel {
            margin-top: 0 !important;
          }
          &.-is-tablet {
            .str-chat__main-panel {
              height: calc(100% - 184px) !important;
            }
          }
        }
      }

      .str-chat-channel .str-chat__container {
        height: 100% !important;
      }
      &.hidden {
        display: flex;
      }

      &-empty {
        background: $gray-2;
        height: 100%;
      }
    }
  }
}

.collaboration--container {
  .conversation-container {
    .chat-footer.-desktop {
      padding-left: 50px;
      padding-right: 50px;
      display: flex !important;
      justify-content: center !important;
      width: 100%;
    }
    .chat-footer.-mobile {
      padding-left: 0;
      padding-right: 30px;
      display: flex !important;
      justify-content: center !important;
      width: 100%;
    }
    &.-is-tablet {
      .str-chat.str-chat-channel {
        height: 100% !important;
        @include lg {
          width: 80vw !important;
          .chat-footer {
            max-width: 80vw !important;
            width: 80vw !important;
          }
        }
      }
      .str-chat-channel .str-chat__container {
        height: 100% !important;
      }
    }
  }
}
