@import 'styles/tools/mixins.scss', 'styles/settings/variables.scss';

.profile-menu {
  $profile-menu-timer: 0.6s;
  border-radius: 12px;
  width: 224px;

  &__isMobile {
    @include sm {
      position: fixed;
      z-index: 2000;
      overflow: scroll;
      top: 0;
      height: calc(100vh - #{$gutter-7});
      width: 100vw;
      border-radius: 0;
      padding-bottom: 100px;

      a {
        color: inherit !important;
        text-decoration: none !important;
      }
    }
  }

  &__modal {
    .modal__container-header-title {
      padding-left: 0;
      text-transform: capitalize;
    }

    .modal__container-content {
      @include sm-md {
        padding-bottom: 120px;
      }
    }
  }

  @include m {
    background: $gray-1;
  }

  &__desktop.ant-dropdown {
    z-index: 1101 !important;

    a {
      font-weight: normal;
      text-decoration: none !important;
      color: inherit !important;
    }
  }

  a:hover {
    text-decoration: none;
  }

  &__logout {
    width: 90%;
    margin: 0 auto;
    position: static !important;
    bottom: 80px;
    left: 0;
    right: 0;
    display: block;
    margin-bottom: 40px;
    margin-top: 10px;
  }

  &__item {
    padding: $gutter-2;
    position: relative;
    cursor: pointer;
    font-weight: 400;
    height: $gutter-6;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &.-logout {
      color: #dc545f;
    }

    &:hover {
      background: $gray-3 !important;
    }

    &-img {
      @include sm {
        width: 36px;
        min-width: 36px;
      }

      &.-no-flex {
        flex-grow: inherit !important;
        margin-right: 8px;
        position: relative;
        top: -2px;
      }
    }

    .badge {
      position: absolute;
      right: 0;
      top: 22px;
      height: 12px;
      width: 12px;
      margin-left: 0;
      background-color: $color-3 !important;

      @include sm-md {
        right: -6px;
      }
    }

    &-new {
      max-width: 28px;
      min-width: 28px;
      height: 14px;
      background: $color-3;
      border-radius: 2px;
      font-weight: bold;
      font-size: 8px;
      color: $gray-1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-left: 8px !important;
    }

    &.-is-marketplace {
      svg {
        width: 24px;
      }
    }

    &:before {
      color: $gray-6;
    }

    @include sm {
      display: flex;
    }

    @include md {
      &:last-child:not(.-no-hover) {
        background: $gray-1;
      }
    }

    .icon-chevron {
      color: $gray-6;
      transform: rotate(270deg);
    }

    .icon-support {
      background-image: url('../../images/support.svg');
    }
    &:before {
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
    }
  }

  &__modal {
    .modal--container-header {
      border-bottom: 1px solid $gray-3;
    }
  }

  &__button {
    background: transparent;
    border: none;
    text-align: start;
  }

  &__header {
    display: flex;
    position: relative;

    &-logout {
      height: $gutter-4;
      width: $gutter-4;
      font-size: 0;
      border: none;
      color: $gray-1;
      background: transparent;
      z-index: 901;
      padding-left: 0;
      padding-right: 0;
      margin-top: $gutter-2;
      &::before {
        content: '\e91f';
        position: relative;
        font-family: 'Moonicon';
        font-size: $gutter-4;
      }
    }
    &-name {
      flex-grow: 1;
      padding-left: 16px;

      margin-top: $gutter-2;
      text-transform: uppercase;
    }
  }
}
