@import 'styles/tools/mixins.scss', 'styles/settings/variables.scss';

.signup {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f2f5f9 inset !important;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focused,
  .ant-input-status-success.ant-input,
  .ant-input-affix-wrapper-status-success {
    background: #f2f5f9 !important;
    -webkit-box-shadow: 0 0 0 30px #f2f5f9 inset !important;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #f2f5f9 inset !important;
    }
  }

  .ant-segmented-item {
    border: 1px solid transparent;

    div {
      opacity: 0.8;
    }

    &.ant-segmented-item-selected {
      border-color: #00a742;

      div {
        font-weight: bold;
        opacity: 1;
      }
    }
  }

  .ant-input-affix-wrapper-status-success,
  .ant-input-affix-wrapper-focused {
    background: #f2f5f9 !important;
    input,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #f2f5f9 inset !important;
    }
  }

  .ant-input:focus,
  .ant-input:active,
  .ant-input-affix-wrapper > input.ant-input:focus {
    background: #f2f5f9 !important;
    -webkit-box-shadow: 0 0 0 30px #f2f5f9 inset !important;
  }

  .ant-input.ant-input-status-success {
    background: #f2f5f9 !important;
  }

  .form-error-message {
    bottom: -15px !important;

    &.-is-default {
      bottom: -20px !important;
    }

    &.-is-static {
      position: relative;
      bottom: -15px !important;
    }
  }

  &__legal {
    margin-top: $gutter-2;
    font-size: 12px;
    text-align: center;
    color: $gray-5;
    a {
      color: $gray-4;
    }
  }
  &__small {
    font-weight: 400;
    font-size: $fontsize-small;
    color: $gray-6;
    cursor: default;
  }
  &__link {
    margin-left: 4px;
    color: $color-3;
    cursor: pointer;
    font-size: 14px;
    text-decoration-line: underline !important;

    &:hover {
      text-decoration: underline;
      color: #3dbf69 !important;
    }
  }
}
