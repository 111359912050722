@import 'styles/tools/mixins.scss', 'styles/settings/variables.scss';
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 999;

  @include sm {
    position: fixed;
    bottom: 0;
    left: 0;
    background: $gray-1;
    z-index: 998;
    right: 1px;
    height: 58px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08);

    &.-in-settings {
      z-index: 99999;
    }
  }

  // @include m-lg {
  @include md {
    position: fixed;
    top: 0;
    width: 100vw;
    height: $gutter-8;
    font-size: $fontsize-medium;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    &-home {
      height: $gutter-8;
    }
  }

  &.-vertical {
    width: auto;
    left: 0;

    .ant-menu-vertical {
      height: 100vh;
    }
  }

  &--logo {
    position: absolute;
    line-height: 0;

    @include sm {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      padding: $gutter-5 0 $gutter-2 0;
      text-align: center;
      background: $color-5;
    }

    @include md {
      top: $gutter-3;
      left: $gutter-4;
    }

    @include lg {
      left: $gutter-8;
    }

    &-img {
      height: $gutter-3;
      width: auto !important;
      max-width: 100px;
    }
  }

  &--item {
    cursor: pointer;
    font-weight: 400;
    white-space: pre;

    @include sm {
      position: relative;
      width: 33.33vw;
      padding-top: $gutter-5;
      font-size: $fontsize-micro;
      text-align: center;
      color: $gray-4;

      &::before {
        position: absolute;
        top: $gutter;
        left: calc(50% - #{$gutter-2});
      }

      &:hover {
        text-decoration: none;
        color: $color-5;
      }
    }

    @include md {
      position: relative;
      height: $gutter-4;
      margin: 0 $gutter-3-2 0 $gutter-3-2;
      color: $gray-5;

      &::before {
        content: '';
      }

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        border-radius: $gutter-1-2 $gutter-1-2 0px 0px;
        bottom: -16px;
        left: 0;
        background-color: $color-3;
        transform-origin: bottom right;
        transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
      }

      &:hover {
        text-decoration: none;

        &::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }

    &__active {
      @include sm {
        width: 33.33vw;
        font-size: $fontsize-micro;
        color: $color-5;
      }

      @include md {
        color: $gray-6;
        font-weight: 400;
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          transform: scaleX(1);
          height: $gutter-1-2;
          border-radius: $gutter-1-2 $gutter-1-2 0px 0px;
          bottom: -16px;
          left: 0;
          background-color: $color-3;
          transform-origin: bottom left;
        }
      }
    }
  }

  &__brand {
    .navbar__brand-hide {
      display: none;
    }

    .navbar--item {
      @include sm {
        width: 50vw;
      }
    }
    .icon-document:before {
      @include sm {
        content: '\e915';
      }
    }
  }
}

.navbar--logo__show {
  .navbar--logo {
    @include sm {
      display: block;
    }
  }
}

.notification-new-message {
  display: flex;
  align-items: center;

  &-name {
    font-weight: bold;
  }

  &__avatar {
    min-width: 64px;
  }
}
