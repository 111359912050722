/* 1 Lato: The font for general purposes */

$lato-regular: url('./../../fonts/Lato/Lato-Regular.ttf');
$lato-italic: url('./../../fonts/Lato/Lato-Italic.ttf');
$lato-bold: url('./../../fonts/Lato/Lato-Bold.ttf');
$lato-bold-italic: url('./../../fonts/Lato/Lato-BoldItalic.ttf');
$lato-black: url('./../../fonts/Lato/Lato-Black.ttf');
$lato-light: url('./../../fonts/Lato/Lato-Light.ttf');
$lato-light-italic: url('./../../fonts/Lato/Lato-LightItalic.ttf');
$moonicon: url('./../../fonts/Moonicon/Moonicon.ttf');

@font-face {
  font-family: 'Open Sans';
  src: $lato-regular;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: $lato-italic;
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: $lato-bold;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: $lato-bold-italic;
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: $lato-black;
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: $lato-light;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: $lato-light-italic;
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

/* 2 Moonicon: The font for the iconographic system */

@font-face {
  font-family: 'Moonicon';
  src: $moonicon;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
