@import 'styles/tools/mixins.scss', 'styles/settings/variables.scss';

.loader {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80px;
  transform: translate(-50%, -50%);

  &.-is-drawer {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    transform: none;

    @include sm {
      position: fixed;
    }
  }

  &__search-info {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    top: 50px;

    @include sm {
      top: 0;
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    &-logo {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}
