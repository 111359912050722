@keyframes grow-top {
  from {
    height: 0;
  }
  to {
    height: 190px;
  }
}

@keyframes fadein-left {
  from {
    left: $gutter-8;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes messages-container {
  from {
    max-width: 0;
  }
  to {
    max-width: 100vw;
  }
}

@keyframes messages-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
