// -------------------- COLORS --------------------

// PRIMARY
$color-1: #F5F9FF; //figma - primary-light
$color-2: #E8F0FE; // primary-semi-light
$color-3: #00A742; // primary
$color-4: #00112C; // primary-hover
$color-5: #00A742; // primary-dark
$color-6: #00A742; // primary-ultra-dark
$color-7: #63C5FC; // from heepsy

// NEUTRAL
$gray-1: #FFFFFF; // white
$gray-2: #F9F9FA; // gray-light
$gray-3: #E8EBEC; // gray semy light
$gray-4: #C8CED4; // gray default
$gray-5: #6F7585; // gray dark
$gray-6: #19202B; // black
$gray-7: #00112C; // from heepsy
$gray-8: #232542; // from heepsy


// BASE COLOR
// red
$red-1: #FFEBED; // error light
$red-2: #FFD9DD; // error hover
$red-3: #EF6D68; // error
//orange
$orange-1: #FFF4DA; // warning
$orange-2: #FFA947; // warning dark
//green
$green-1: #F2FFF3; // success
$green-2: #19A35A; // success dark

// -------------------- WIDTHS --------------------

$width-120: 120px;
$width-300: 300px;
$width-320: 320px;
$width-324: 324px;
$width-366: 366px;
$width-520: 520px;
$width-600: 600px;
$width-719: 719px;

// -------------------- MEASURES --------------------

$gutter: 8px;

$gutter-2: calc(#{$gutter} * 2);
$gutter-2-5: calc(#{$gutter} * 2.25); // 18px
$gutter-3: calc(#{$gutter} * 3);
$gutter-4: calc(#{$gutter} * 4);
$gutter-5: calc(#{$gutter} * 5);
$gutter-6: calc(#{$gutter} * 6);
$gutter-7: calc(#{$gutter} * 7);
$gutter-8: calc(#{$gutter} * 8);
$gutter-9: calc(#{$gutter} * 9);
$gutter-10: calc(#{$gutter} * 10);
$gutter-11: calc(#{$gutter} * 11);
$gutter-12: calc(#{$gutter} * 12);
$gutter-13: calc(#{$gutter} * 13);
$gutter-13-2: calc(#{$gutter} * (13 / 2));
$gutter-14: calc(#{$gutter} * 14);
$gutter-15: calc(#{$gutter} * 15);
$gutter-16: calc(#{$gutter} * 16);
$gutter-17: calc(#{$gutter} * 17);
$gutter-18: calc(#{$gutter} * 18);
$gutter-19: calc(#{$gutter} * 19);
$gutter-20: calc(#{$gutter} * 20);
$gutter-21: calc(#{$gutter} * 21);
$gutter-22: calc(#{$gutter} * 22);
$gutter-23: calc(#{$gutter} * 23);
$gutter-24: calc(#{$gutter} * 24);
$gutter-25: calc(#{$gutter} * 25);
$gutter-26: calc(#{$gutter} * 26);
$gutter-27: calc(#{$gutter} * 27);
$gutter-29: calc(#{$gutter} * 29);
$gutter-30: calc(#{$gutter} * 30);
$gutter-31: calc(#{$gutter} * 31);
$gutter-33: calc(#{$gutter} * 33);
$gutter-34: calc(#{$gutter} * 34);
$gutter-35: calc(#{$gutter} * 35);
$gutter-37: calc(#{$gutter} * 37);
$gutter-38: calc(#{$gutter} * 38);
$gutter-40: calc(#{$gutter} * 40);
$gutter-45: calc(#{$gutter} * 45);
$gutter-50: calc(#{$gutter} * 50);
$gutter-55: calc(#{$gutter} * 55);
$gutter-60: calc(#{$gutter} * 60);
$gutter-65: calc(#{$gutter} * 65);
$gutter-70: calc(#{$gutter} * 70);
$gutter-75: calc(#{$gutter} * 75);
$gutter-77: calc(#{$gutter} * 77);
$gutter-80: calc(#{$gutter} * 80);
$gutter-85: calc(#{$gutter} * 85);
$gutter-90: calc(#{$gutter} * 90);
$gutter-94: calc(#{$gutter} * 94);
$gutter-95: calc(#{$gutter} * 95);
$gutter-100: calc(#{$gutter} * 100);
$gutter-120: calc(#{$gutter} * 120);
$gutter-180: calc(#{$gutter} * 180);
$gutter-200: calc(#{$gutter} * 200);

$gutter-1-1: calc(#{$gutter} / 8); // 1px
$gutter-3-8: calc(#{$gutter} * 3 / 8); // 3px
$gutter-5-8: calc(#{$gutter} * 5 / 8); // 5px
$gutter-3-4: calc(#{$gutter} * 3 / 4); // 6px
$gutter-5-4: calc(#{$gutter} * 5 / 4); // 10px
$gutter-15-8: calc(#{$gutter} * 15 / 8); // 15px
$gutter-2-18: calc(#{$gutter} * 18 / 8); // 18px
$gutter-8-2: calc(#{$gutter} * 2.5); // 20px
$gutter-28: calc(#{$gutter} * 3.5); // 28px
$gutter-140: calc(#{$gutter} * 17.5); // 140px
$gutter-192: calc(#{$gutter} * 24); // 192px
$gutter-36: calc(#{$gutter} * 36); // 288px

$gutter-1-2: calc(#{$gutter} / 2);
$gutter-1-4: calc(#{$gutter} / 4);
$gutter-3-2: calc(#{$gutter} * 3 / 2);

// -------------------- FONTS --------------------

$font-primary: 'Open Sans', sans-serif;
$font-numerical: 'Merriweather', serif;
$font-icon: 'Moonicon';

// -------------------- FONT SIZES --------------------

$fontsize: 16px;

$fontsize-micro: calc(#{$fontsize} * 0.625);
$fontsize-xsmall: calc(#{$fontsize} * 0.75);
$fontsize-small: calc(#{$fontsize} * 0.875);
$fontsize-medium: $fontsize;
$fontsize-large: calc(#{$fontsize} * 1.125);
$fontsize-xlarge: calc(#{$fontsize} * 1.25);
$fontsize-xxlarge: calc(#{$fontsize} * 1.5);
$fontsize-xxxlarge: calc(#{$fontsize} * 2);

$fontsize-huge: calc(#{$fontsize} * 3.5);

// -------------------- SCREEN SIZES --------------------

$screen-xs: 320px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 960px;
$screen-xlg: 1200px;
$screen-xxlg: 1562px;
$screen-lg-2: 992px;
$custom-screen-lg: 1270px;
$custom-screen-lg-1-2: 1403px;
$custom-screen-lg2: 1600px;

//------ BODY MAX WIDTH ----------
$body-max-width: 1344px;

